export default [
  "cupcake",
  "cake",
  "candy",
  "pastry",
  "sweet",
  "cookie",
  "ice cream",
  "pie",
  "pudding",
  "tart",
  "croissant",
  "sweet roll",
  "muffin",
  "lollipop",
  "caramels",
  "lemon drops",
  "toffee",
  "chocolate",
  "jujubes",
  "jelly",
  "jelly beans",
  "marshmallow",
  "marzipan",
  "halvah",
  "gummies",
  "gummi bears",
  "tootsie roll",
  "liquorice",
  "chupa chups",
  "bonbon",
  "dessert",
  "sugar plum",
  "dragée",
  "apple pie",
  "biscuit",
  "wafer",
  "chocolate bar",
  "candy canes",
  "gingerbread",
  "donut",
  "cheesecake",
  "oat cake",
  "carrot cake",
  "shortbread",
  "fruitcake",
  "soufflé",
  "tiramisu",
  "chocolate cake",
  "brownie",
  "macaroon",
  "icing",
  "powder",
  "topping",
  "jelly-o",
  "cake",
  "sesame snaps",
  "danish",
  "bear claw",
  "cotton candy",
  "caramel corn"
];
